import React, { useEffect, useState } from "react";
import { BigNumbers } from "ethers";
import { Link } from "react-router-dom";
import { utils } from "ethers";
import Modal from "react-bootstrap/Modal";
import { Image } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import depthRightsImg from "./../assets/images/icons/depthrights.svg";
import depositAmountImg from "./../assets/images/icons/deposit.svg";
import loaderGif from "./../assets/images/loader.gif";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Accordion from "react-bootstrap/Accordion";


import { useSelector, useDispatch } from "react-redux";
import CountUp from "react-countup";
import Moment from "react-moment";
import "moment-timezone";
import Cookies from "universal-cookie";

import {
  addDepositStats,
  changeToDefault,
  addWithdrawnStats,
  addAccountStats,
  addReferralStats,
  setUserDepth,
  setUserMissedAmont,
  filterDepositStats,
  sortFilteredDeposits,
} from "../features/wallets";
import { NATIVE_TOKEN_ADDRESS } from "@thirdweb-dev/sdk";

import { setPrice, setContractStats } from "../features/contract";
import { fetchNews } from "../features/theme";
import {
  useContract,
  useContractRead,
  useContractWrite,
  useBalance,
} from "@thirdweb-dev/react";
import ConnectButton from "../components/ConnectButton";

//components
import OneStop from "./../components/Home/OneStop";
import RecentNews from "./../components/Home/RecentNews";

//images
import baner1 from "./../assets/images/home-banner/img1.png";
import baner2 from "./../assets/images/home-banner/img2.png";
import Shape1 from "./../assets/images/home-banner/shape1.png";
import Shape3 from "./../assets/images/home-banner/shape3.png";
import friend from "./../assets/images/icons/availible.svg";

import maticLogo from "./../assets/images/maticLogo.webp";

import bloglg from "./../assets/images/blog/blog-ig.png";
import avatar3 from "./../assets/images/avatar/avatar3.jpg";
import {
  parseUserDepositStats,
  parseUserWithdrawStats,
  hndPrsFl,
  parseAccountStats,
  parsedSiteStats,
  parsedReferralStats,
  toEther,
  toTimeStamp,
} from "../helpers";

function Home() {
  function scrollToTestDiv() {
    const divElement = document.getElementById("calculate");
    divElement.scrollIntoView({ behavior: "smooth", top: 120 });
  }
  const cookies = new Cookies();
  const dispatch = useDispatch();
  const { contract } = useContract(process.env.REACT_APP_CONTRACT_ADDRESS);
  const copyLink = () => {
    navigator.clipboard.writeText("https://maticfund.com/ref?a=" + address);
    toast.success("Link was Copied.");
  };
  const {
    isLogin,
    address,
    totalDeposits,
    totalUsdDeposits,
    available,
    oldAvailable,
    percentRate,
    activeDeposits,
    activeUsdDeposits,
    countOfTotalDeposits,
    countOfActiveDeposit,
    totalWithdrawn,
    totalUsdWithdrawn,
    totalRefBonus,
    totalUsdRefBonus,
    firstLineCount,
    depth,
    depthRights,
    totalTeamCount,
    missedAmount,
    totalTeamInvest,
    filteredDeposits,
  } = useSelector((state) => state.wallet);
  const { news } = useSelector((state) => state.theme);

  const { price, totalInvest, totalInvestCount, tradeProfit } = useSelector(
    (state) => state.contract
  );

  /* Contract Call */
  const {
    data: userDepositStats,
    isLoading: depositLoading,
    refetch: refetchDepositStats,
  } = useContractRead(contract, "getUserDepositStats", [address]);

  const {
    data: accountStats,
    isLoading: accountLoading,
    refetch: refetchAccountStats,
  } = useContractRead(contract, "getAccountStats", [address]);
  const {
    data: siteStats,
    isLoading: siteLoading,
    refetch: refetchSiteStats,
  } = useContractRead(contract, "getSiteStats");
  const {
    data: withdrawStats,
    isLoading: withdrawsLoading,
    refetch: refetchWithdrawStats,
  } = useContractRead(contract, "getUserWithdrawStats", [address]);
  const {
    data: referralStats,
    isLoading: referralLoading,
    refetch: refetchUserReferralStats,
  } = useContractRead(contract, "getUserReferralsStats", [address]);
  const {
    data: contractBalanceGetted,
    isLoading: contractBalanceLoading,
    refetch: refetchContractBalance,
  } = useContractRead(contract, "getContractBalance");

  const {
    data: latestPrice,
    isLoading: priceLoading,
    refetch: refetchLatestPrice,
  } = useContractRead(contract, "getLatestPrice");
  const {
    data: userAffDepth,
    isLoading: userAffLoading,
    refetch: refetchAffilliateDepth,
  } = useContractRead(contract, "getUserAffiliateDepth", [address]);
  const {
    data: MissedAmount,
    isLoading: missedLoading,
    refetch: refetchTotalMissedAmount,
  } = useContractRead(contract, "getTotalMissedAmount", [address]);
  const { data: isActiveC, isLoading: isActiveLoading } = useContractRead(
    contract,
    "isActive",
    [address]
  );
  const { data, isLoading } = useBalance(NATIVE_TOKEN_ADDRESS);
  const { mutateAsync: invest } = useContractWrite(contract, "invest");
  const { mutateAsync: withdraw } = useContractWrite(contract, "withdraw");

  const [calcUsdAmount, setCalcUsdAmount] = useState("");
  const [calcMtcAmount, setCalcMtcAmount] = useState("");
  const [contractBalance, setContractBalance] = useState(0);
  const [totalWithdrawnAmount, setTotalWithdrawnAmount] = useState(0);
  const [calcError, setCalcError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [sortDate, setSortDate] = useState(true);
  const [filter, setFilter] = useState({ state: "all" });
  const [balance, setBalance] = useState(0);
  const [open, setOpen] = React.useState(false);
  const getRef = () => {
    return cookies.get("refAddress")
      ? cookies.get("refAddress")
      : process.env.REACT_APP_DEFAULT_ADDRESS;
  };
  const handleRate = (val) => {
    if (balance > 1) {
      if (val == 100) {
        setCalcMtcAmount(
          parseFloat(((balance - 1) * parseFloat(val)) / 100).toFixed(4)
        );
        setCalcUsdAmount(
          parseFloat(((balance - 1) * price * parseFloat(val)) / 100).toFixed(2)
        );
        setCalcError(balance - 1 < 10);
      } else {
        setCalcMtcAmount(
          parseFloat((balance * parseFloat(val)) / 100).toFixed(4)
        );
        setCalcUsdAmount(
          parseFloat((balance * price * parseFloat(val)) / 100).toFixed(2)
        );
        setCalcError(balance < 10);
      }
    } else {
      setCalcMtcAmount(0);
    }
  };
  const handleOpen = () => {
    if (calcUsdAmount >= 10) {
      setOpen(true);
    } else {
      toast.error("The minimum investment amount is $10.");
      setCalcError(true);
    }
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const data = await invest({
        args: [getRef()],
        overrides: {
          value: utils.parseEther(calcMtcAmount),
        },
      });
      if (data.receipt.blockHash) {
        setLoading(false);
        setOpen(false);
        toast.success("Congratulations ! The invest was completed. ");
      }
    } catch (err) {
      setOpen(false);
      setLoading(false);
      toast.error("There was an error." + err);
    }
  };
  const withdrawSubmit = async () => {
    try {
      setLoading(true);
      const data = await withdraw({
        args: [],
      });
      if (data.receipt.blockHash) {
        setLoading(false);
        toast.success("Congratulations ! The withdraw was completed. ");
      }
    } catch (err) {
      setLoading(false);
      toast.error("There was an error." + err);
    }
  };

  useEffect(() => {
    if (!isActiveLoading && isActiveC) setIsActive(isActiveC);
  }, [isActiveLoading,address]);

  useEffect(() => {
    if (!isLoading) setBalance(parseFloat(data.displayValue).toFixed(4));
  }, [isLoading, address]);

  useEffect(() => {
    if (!priceLoading) {
      if (latestPrice) dispatch(setPrice(toEther(latestPrice)));
    }
  }, [priceLoading]);

  useEffect(() => {
    if (!userAffLoading && userAffDepth) {
      if (userAffDepth) dispatch(setUserDepth(toTimeStamp(userAffDepth) + 1));
    }
  }, [userAffLoading, address]);

  useEffect(() => {
    if (!missedLoading && MissedAmount) {
      dispatch(setUserMissedAmont(toTimeStamp(MissedAmount) / 1e18));
    }
  }, [missedLoading, address]);

  useEffect(() => {
    if (!referralLoading) {
      if (referralStats) {
        let parsefRS = parsedReferralStats(referralStats);
        dispatch(addReferralStats(parsefRS));
      }
    }
  }, [referralLoading, address]);

  useEffect(() => {
    if (!siteLoading && siteStats) {
      let parsedSS = parsedSiteStats(siteStats);
      //console.log("parsedSS", parsedSS);
      dispatch(setContractStats(parsedSS));
    }
  }, [siteLoading]);

  useEffect(() => {
    if (!accountLoading) {
      if (accountStats) {
        let parsedAS = parseAccountStats(accountStats);
        dispatch(addAccountStats(parsedAS));
      }
    }
  }, [accountLoading, address]);

  useEffect(() => {
    if (isLogin && !depositLoading && !accountLoading) {
      let parsedUDS = parseUserDepositStats(userDepositStats);
      dispatch(addDepositStats(parsedUDS));
    } else {
      dispatch(changeToDefault());
    }
  }, [isLogin, address, accountLoading, depositLoading]);

  useEffect(() => {
    if (isLogin && !withdrawsLoading) {
      let parsedWdr = parseUserWithdrawStats(withdrawStats);
      dispatch(addWithdrawnStats(parsedWdr));
    } else {
      dispatch(changeToDefault());
    }
  }, [isLogin, address, withdrawsLoading]);

  useEffect(() => {
    dispatch(fetchNews());
  }, []);

  useEffect(() => {
    let intervalId = setInterval(() => {
      refetchDepositStats().then((res) => {
        if (isLogin && res.isFetched && res.data) {
          let parsedUDS = parseUserDepositStats(res.data);
          dispatch(addDepositStats(parsedUDS));
        } else {
          dispatch(changeToDefault());
        }
      });
      refetchAccountStats().then((res) => {
        if (res.isFetched) {
          if (res.data) {
            let parsedAS = parseAccountStats(res.data);
            dispatch(addAccountStats(parsedAS));
          }
        }
      });
      refetchSiteStats().then((res) => {
        if (res.isFetched) {
          if (res.data) {
            let parsedSS = parsedSiteStats(res.data);
            dispatch(setContractStats(parsedSS));
          }
        }
      });
      refetchContractBalance().then((res) => {
        if (res.isFetched) {
          if (res.data) {
            console.log("res.data", toEther(res.data));
            setContractBalance(toEther(res.data));
          }
        }
      });
      refetchWithdrawStats().then((res) => {
        if (res.isFetched) {
          if (res.data) {
            let parsedWdr = parseUserWithdrawStats(res.data);
            dispatch(addWithdrawnStats(parsedWdr));
          }
        }
      });
      refetchUserReferralStats().then((res) => {
        if (res.isFetched) {
          if (res.data) {
            let parsefRS = parsedReferralStats(res.data);
            dispatch(addReferralStats(parsefRS));
          }
        }
      });
      refetchLatestPrice().then((res) => {
        if (res.isFetched) {
          if (res.data) {
            dispatch(setPrice(toEther(res.data)));
          }
        }
      });
      refetchAffilliateDepth().then((res) => {
        if (res.isFetched) {
          if (res.data) {
            dispatch(setUserDepth(toTimeStamp(res.data) + 1));
          }
        }
      });
      refetchTotalMissedAmount().then((res) => {
        if (res.isFetched) {
          if (res.data) {
            dispatch(setUserMissedAmont(toTimeStamp(res.data) / 1e18));
          }
        }
      });
    }, 2000);
    return () => clearInterval(intervalId);
  }, []);

  const handleFilter = (filterState) => {
    setFilter(filterState);
    dispatch(filterDepositStats({ filterState }));
  };

  const [selectedPage, setSelectedPage] = useState(1);
  const [itemOffset, setItemOffset] = useState(0);

  const itemsPerPage = 5;
  const endOffset = itemOffset + itemsPerPage;
  let currentItems = filteredDeposits.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(filteredDeposits.length / itemsPerPage);

  const handleSorting = () => {
    setSortDate(!sortDate);
    dispatch(sortFilteredDeposits(sortDate));
  };
  const handlePageClick = (slt) => {
    if (slt == "next") {
      if (selectedPage < pageCount) {
        setSelectedPage(selectedPage + 1);
        setItemOffset(itemOffset + itemsPerPage);
      }
    } else {
      if (selectedPage > 1) {
        setSelectedPage(selectedPage - 1);
        setItemOffset(itemOffset - itemsPerPage);
      }
    }
  };

  //Functions
  const handleCalculate = (e) => {
    let target = e.target.name;
    let val = e.target.value;
    if (target === "usdAmount") {
      setCalcError(val < 10);
      setCalcUsdAmount(val);
      setCalcMtcAmount(hndPrsFl(val / price));
    } else {
      let usdAmount = hndPrsFl(val * price);
      setCalcError(usdAmount < 10);
      setCalcMtcAmount(val);
      setCalcUsdAmount(usdAmount);
    }
  };
  useEffect(() => {
    if(contractBalance && totalInvest){
      setTotalWithdrawnAmount(totalInvest - contractBalance - totalInvest * 0.22);
    }
  }, [contractBalance, totalInvest]);

  return (
    <>
      <ToastContainer />
      {loading && (
        <div
          style={{
            zIndex: 99999,
            height: "100vh",
            width: "100%",
            textAlign: "center",
            position: "fixed",
            display: "block",
            backgroundColor: "rgba(0, 0, 0, 0.20)",
          }}>
          <Image src={loaderGif} style={{ marginTop: "20%" }} />
        </div>
      )}
      <div className="page-content">
        <div className="main-bnr style-1">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 text-center">
                <h1 className="">
                  Matic Fund Smart Contract <br />
                  Capable of Trading Pol/USD{" "}
                </h1>
                <p className="text text-primary ">
                  Earn daily profits from 0.5% to 1% by depositing Pol
                  (POLYGON)
                </p>
                <ConnectButton />
                <ul className="image-before">
                  <li className="left-img">
                    <img src={baner1} alt="" />
                  </li>
                  <li className="right-img">
                    <img src={baner2} alt="" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <img className="bg-shape1" src={Shape1} alt="" />
          <img className="bg-shape2" src={Shape1} alt="" />
          <img className="bg-shape3" src={Shape3} alt="" />
          <img className="bg-shape4" src={Shape3} alt="" />
        </div>
        {/* Contract Info */}
        <div className="clearfix bg-primary-light" id="about-us">
          <div className="container">
            <div className="currancy-wrapper">
              <div className="row justify-content-center">
                <div
                  className="col-lg-3 col-md-4 m-b30 wow fadeInUp"
                  data-wow-delay="0.2s">
                  <div className="icon-bx-wraper style-1 box-hover">
                    <div className="icon-media">
                      <img src={maticLogo} alt="" />
                      <div className="icon-info">
                        <h5 className="title">
                          <CountUp
                            end={totalInvest * 0.35}
                            decimals={2}
                            decimal=","
                            style={{ fontSize: "1.125rem" }}
                          />
                        </h5>
                        <span>Pol</span>
                      </div>
                    </div>
                    <div className="icon-content">
                      <ul className="price">
                        <li style={{ justifyContent: "end" }}>
                          <h6 className="mb-0 amount">
                            $
                            <CountUp
                              end={totalInvest * price * 0.35}
                              decimals={2}
                              style={{ fontSize: "1.125rem" }}
                              decimal=","
                            />
                          </h6>
                        </li>
                        <li style={{ justifyContent: "end" }}>
                          <span>Active Invest Amount</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-3 col-md-4 m-b30 wow fadeInUp"
                  data-wow-delay="0.2s">
                  <div className="icon-bx-wraper style-1 box-hover">
                    <div className="icon-media d-flex justify-content-center">
                      <div className="icon-info">
                        <h5 className="title">
                          <CountUp
                            end={totalInvestCount}
                            style={{ fontSize: "2em" }}
                          />
                        </h5>
                      </div>
                    </div>
                    <div className="icon-content">
                      <ul className="price">
                        <li style={{ justifyContent: "end" }}>
                          <span style={{ marginTop: 23 }}>
                            Total Invest Count
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-3 col-md-4 m-b30 wow fadeInUp"
                  data-wow-delay="0.2s">
                  <div className="icon-bx-wraper style-1 box-hover">
                    <div className="icon-media">
                      <img src={maticLogo} alt="" />
                      <div className="icon-info">
                        <h5 className="title">
                          <CountUp
                            end={totalInvest * 0.22}
                            decimals={2}
                            style={{ fontSize: "1.125rem" }}
                            decimal=","
                          />
                        </h5>
                        <span>Pol</span>
                      </div>
                    </div>
                    <div className="icon-content">
                      <ul className="price">
                        <li style={{ justifyContent: "end" }}>
                          <h6 className="mb-0 amount">
                            $
                            <CountUp
                              end={totalInvest * 0.22 * price}
                              decimals={2}
                              style={{ fontSize: "1.125rem" }}
                              decimal=","
                            />
                          </h6>
                        </li>
                        <li style={{ justifyContent: "end" }}>
                          <span>Total Referral Rewards</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-3 col-md-4 m-b30 wow fadeInUp"
                  data-wow-delay="0.2s">
                  <div className="icon-bx-wraper style-1 box-hover">
                    <div className="icon-media">
                      <img src={maticLogo} alt="" />
                      <div className="icon-info">
                        <h5 className="title">
                          <CountUp
                            end={totalWithdrawnAmount}
                            decimals={2}
                            decimal=","
                            style={{ fontSize: "1rem" }}
                          />
                        </h5>
                        <span>Pol</span>
                      </div>
                    </div>
                    <div className="icon-content">
                      <ul className="price">
                        <li style={{ justifyContent: "end" }}>
                          <h6 className="mb-0 amount">
                            $
                            <CountUp
                              end={totalWithdrawnAmount * price}
                              decimals={2}
                              style={{ fontSize: "1.125rem" }}
                              decimal=","
                            />
                          </h6>
                        </li>
                        <li style={{ justifyContent: "end" }}>
                          <span>Total Withdrawn Amount</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="clearfix section-wrapper1 bg-primary-light">
          <div className="container">
            <div
              className="content-inner-1"
              style={{ paddingTop: 30, paddingBottom: 30 }}>
              {isLogin && (
                <div className="section-head text-center">
                  <h2 className="title">Personal Dashboard</h2>
                </div>
              )}
              {!isLogin && (
                <div className="section-head text-center">
                  <h2 className="title">What is MATIC FUND ?</h2>
                  <p style={{ maxWidth: "none" }} id="invest">
                    Matic Fund is a fully decentralized smart contract developed
                    on the Polygon blockchain network, aiming to profit from the
                    price fluctuations of the POL token against the dollar and
                    offering gains to its investors. The Matic Fund smart
                    contract employs a trading strategy that promises
                    participants a return of either double the amount of POL
                    or double the dollar value.
                  </p>
                </div>
              )}

              {isLogin && (
                <div className="row">
                  <div className="col-lg-6 m-b30">
                    <div className="icon-bx-wraper style-2">
                      <div className="icon-media">
                        <img src={depositAmountImg} alt="" />
                      </div>
                      <div className="icon-content">
                        <h4 className="title">Your Deposits Amount</h4>
                        <p>
                          Total Deposits :{" "}
                          <CountUp
                            end={totalDeposits}
                            decimals={2}
                            decimal=","
                          />{" "}
                          POL ($
                          <CountUp
                            end={totalUsdDeposits}
                            decimals={2}
                            decimal=","
                          />
                          ) <br />
                          Active Deposits :{" "}
                          <CountUp
                            end={activeDeposits}
                            decimals={2}
                            decimal=","
                          />
                          POL ($
                          <CountUp
                            end={activeUsdDeposits}
                            decimals={2}
                            decimal=","
                          />
                          ) <br />
                          Count of Total Deposits :{" "}
                          <CountUp end={countOfTotalDeposits} />
                          <br />
                          Count of Active Deposits :{" "}
                          <CountUp end={countOfActiveDeposit} /> <br />
                        </p>
                        <Link
                          className="btn btn-primary btn-gradient btn-shadow"
                          to={"#"}
                          onClick={() => scrollToTestDiv()}>
                          New Deposit
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 m-b30">
                    <div className="icon-bx-wraper style-2">
                      <div className="icon-media">
                        <img src={friend} alt="" />
                      </div>
                      <div className="icon-content">
                        <h4 className="title d-flex flex-row justify-content-between align-items-center">
                          Available Balance
                          <span className="display-6">
                            %
                            <CountUp
                              end={percentRate / 100}
                              decimals={2}
                              decimal=","
                            />
                          </span>
                        </h4>
                        <p>
                          <b>
                            Withdrawable :{" "}
                            <CountUp start={oldAvailable.oldAvailable} end={oldAvailable.available} decimals={6} decimal="," />{" "}
                            POL ($
                            <CountUp
                              start={oldAvailable.oldAvailable * price}
                              end={oldAvailable.available * price}
                              decimals={6}
                              decimal=","
                            />
                            )
                          </b>{" "}
                          <br />
                          Amount of Total Withdrawn :{" "}
                          <CountUp
                            end={totalWithdrawn - totalRefBonus}
                            decimals={2}
                            decimal=","
                          />{" "}
                          POL ($
                          <CountUp
                            end={totalUsdWithdrawn - totalUsdRefBonus}
                            decimals={2}
                            decimal=","
                          />
                          ) <br />
                          Daily Reward Percent : %{" "}
                          <CountUp end={0.5} decimals={2} decimal="," />
                          <br />
                          Hold Reward Percent : %{" "}
                          <CountUp
                            end={percentRate / 100 - 0.5}
                            decimals={2}
                            decimal=","
                          />
                          <br />
                        </p>

                        <Button
                          className="btn btn-primary btn-gradient btn-shadow"
                          onClick={withdrawSubmit}>
                          Withdraw
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          {isLogin && (
            <>
              <div className="container">
                <div className="row">
                  <div className="col-xxl-12">
                    <div className="card">
                      <div className="card-header border-0 pb-2 flex-wrap">
                        <h4 className="heading me-2">Deposit History</h4>
                        <nav>
                          <div
                            className="order nav nav-tabs nav"
                            role="tablist">
                            <button
                              className={
                                filter.state == "all"
                                  ? "nav-link active"
                                  : "nav-link"
                              }
                              onClick={() => {
                                handleFilter({ state: "all" });
                              }}>
                              All
                            </button>
                            <button
                              className={
                                filter.state == "active"
                                  ? "nav-link active"
                                  : "nav-link"
                              }
                              onClick={() => {
                                handleFilter({ state: "active" });
                              }}>
                              Active
                            </button>
                            <button
                              className={
                                filter.state == "passive"
                                  ? "nav-link active"
                                  : "nav-link"
                              }
                              onClick={() => {
                                handleFilter({ state: "passive" });
                              }}>
                              Passive
                            </button>
                          </div>
                        </nav>
                      </div>
                      <div className="card-body pt-2">
                        <div className="tab-content">
                          <div className="fade tab-pane active show">
                            <div className="table-responsive dataTablehistory">
                              <div className="dataTables_wrapper no-footer">
                                <table
                                  className="table shadow-hover dataTable display"
                                  style={{ minWidth: 845 }}>
                                  <thead>
                                    <tr>
                                      <th>Status</th>
                                      <th
                                        onClick={() =>
                                          handleSorting(!sortDate)
                                        }>
                                        Date{" "}
                                        {!sortDate ? (
                                          <i className="fa-solid fa-chevron-up"></i>
                                        ) : (
                                          <i className="fa-solid fa-chevron-down"></i>
                                        )}
                                      </th>
                                      <th>Deposit (Pol)</th>
                                      <th>Deposit ($)</th>
                                      <th>Withdraw (Pol)</th>
                                      <th>Withdraw ($)</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {currentItems.map((deposit, index) => (
                                      <tr className="" key={index}>
                                        <td>
                                          {deposit.active ? (
                                            <p className="btn btn-success shadow btn-xs sharp">
                                              <i className="fa fa-circle-check"></i>
                                            </p>
                                          ) : (
                                            <p className="btn btn-danger shadow btn-xs sharp">
                                              <i className="fa fa-ban"></i>
                                            </p>
                                          )}
                                        </td>
                                        <td>
                                          {" "}
                                          <Moment
                                            className="text-wrap"
                                            unix
                                            format="HH:mm:ss / DD-MM-YYYY"
                                            tz={
                                              Intl.DateTimeFormat().resolvedOptions()
                                                .timeZone
                                            }>
                                            {deposit.start}
                                          </Moment>
                                        </td>
                                        <td>{deposit.amount}</td>
                                        <td>{deposit.usd_amount}</td>
                                        <td>{deposit.withdrawn}</td>
                                        <td>{deposit.usd_withdrawn}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                                <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-3">
                                  <div className="dataTables_info">
                                    Showing {selectedPage} to {pageCount}
                                  </div>
                                  <div
                                    className="dataTables_paginate paging_simple_numbers mb-0"
                                    id="application-tbl1_paginate">
                                    <a
                                      className={
                                        selectedPage == 1
                                          ? "paginate_button previous"
                                          : "paginate_button previous current"
                                      }
                                      disabled={selectedPage == 1}
                                      style={
                                        selectedPage == 1
                                          ? { opacity: 0.1 }
                                          : {}
                                      }
                                      onClick={() => handlePageClick("prev")}>
                                      <i className="fa fa-angle-double-left"></i>
                                    </a>

                                    <span>
                                      <button className="paginate_button">
                                        {selectedPage}
                                      </button>
                                    </span>

                                    <a
                                      className={
                                        selectedPage == pageCount
                                          ? "paginate_button previous"
                                          : "paginate_button previous current"
                                      }
                                      disabled={selectedPage == pageCount}
                                      style={
                                        selectedPage == pageCount
                                          ? { opacity: 0.1 }
                                          : {}
                                      }
                                      onClick={() => handlePageClick("next")}>
                                      <i className="fa fa-angle-double-right"></i>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container">
                <div className="row ">
                  <div className="section-head text-center">
                    <h2 className="title">Affiliate Program</h2>
                  </div>
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-lg-6 m-b30">
                        <div
                          className="icon-bx-wraper style-2"
                          style={{ background: "#ffffff" }}>
                          <div className="icon-media">
                            <img src={depthRightsImg} alt="" />
                          </div>
                          <div className="icon-content row">
                            <h4
                              className="title d-flex flex-row justify-content-between align-items-center"
                              style={{ color: "#1c2e9e" }}>
                              Depth Rights
                              <span className="display-6">
                                <b>
                                  <CountUp end={depthRights} />
                                </b>
                              </span>
                            </h4>
                            <p style={{ color: "#1c2e9e" }}>
                              FirstLine Count : <CountUp end={firstLineCount} />
                              <br />
                              Your Active Invest : $ {activeUsdDeposits} <br />
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center mt-4">
                  <div
                    className="col-lg-4 col-md-4 col-sm-12 m-b30 wow fadeInUp"
                    data-wow-delay="0.2s">
                    <div className="icon-bx-wraper style-1 box-hover">
                      <div className="icon-media">
                        <img src={maticLogo} alt="" />
                        <div className="icon-info">
                          <h5 className="title">
                            <CountUp
                              end={totalTeamInvest}
                              decimals={2}
                              style={{ fontSize: "1.125rem" }}
                              decimal=","
                            />
                          </h5>
                          <span>Pol</span>
                        </div>
                      </div>
                      <div className="icon-content">
                        <ul className="price">
                          <li style={{ justifyContent: "end" }}>
                            <h6 className="mb-0 amount">
                              $
                              <CountUp
                                end={totalTeamInvest * price}
                                style={{ fontSize: "1.125rem" }}
                                decimals={2}
                                decimal=","
                              />
                            </h6>
                          </li>
                          <li style={{ justifyContent: "end" }}>
                            <span>Total Team Invest</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-4 col-md-4 col-sm-12 m-b30 wow fadeInUp"
                    data-wow-delay="0.2s">
                    <div className="icon-bx-wraper style-1 box-hover">
                      <div className="icon-media">
                        <img src={maticLogo} alt="" />
                        <div className="icon-info">
                          <h5 className="title">
                            <CountUp
                              end={totalRefBonus}
                              decimals={2}
                              style={{ fontSize: "1.125rem" }}
                              decimal=","
                            />
                          </h5>
                          <span>Pol</span>
                        </div>
                      </div>
                      <div className="icon-content">
                        <ul className="price">
                          <li style={{ justifyContent: "end" }}>
                            <h6 className="mb-0 amount">
                              $
                              <CountUp
                                end={totalUsdRefBonus}
                                decimals={2}
                                style={{ fontSize: "1.125rem" }}
                                decimal=","
                              />
                            </h6>
                          </li>
                          <li style={{ justifyContent: "end" }}>
                            <span>Referral Earnings</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-4 col-md-4 col-sm-12 m-b30 wow fadeInUp"
                    data-wow-delay="0.2s">
                    <div className="icon-bx-wraper style-1 box-hover">
                      <div className="icon-media">
                        <img src={maticLogo} alt="" />
                        <div className="icon-info">
                          <h5 className="title">
                            <CountUp
                              end={missedAmount}
                              decimals={2}
                              style={{ fontSize: "1.125rem" }}
                              decimal=","
                            />
                          </h5>
                          <span>Pol</span>
                        </div>
                      </div>
                      <div className="icon-content">
                        <ul className="price">
                          <li style={{ justifyContent: "end" }}>
                            <h6 className="mb-0 amount">
                              $
                              <CountUp
                                end={missedAmount * price}
                                decimals={2}
                                style={{ fontSize: "1.125rem" }}
                                decimal=","
                              />
                            </h6>
                          </li>
                          <li style={{ justifyContent: "end" }}>
                            <span>Missed Amount</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="container">
                <div className="row">
                  <div className="col-md-6 col-xs-12  mx-auto">
                    <div
                      className="input-group mt-5 refLink"
                      style={{ maxHeight: 40 }}>
                      <span
                        style={{ maxHeight: 40 }}
                        className="btn btn-success">
                        Ref Link
                      </span>
                      <input
                        style={{ maxHeight: 40 }}
                        type="text"
                        className="form-control"
                        disabled
                        value={
                          isActive
                            ? "https://maticfund.com/ref?a=" + address
                            : "You must invest for activation"
                        }
                      />
                      <button
                        style={{ maxHeight: 40 }}
                        onClick={() => {
                          copyLink();
                        }}
                        className="btn btn-outline-primary left-radius">
                        Copy
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container" id="calculate">
                <div className="row justify-content-center mt-4">
                  <div
                    className="section-head text-center"
                    style={{ marginBottom: 20 }}>
                    <h2 className="title mt-3">Depth Details</h2>
                    <p style={{ maxWidth: "none !important" }} id="invest">
                      Total Invest Count : <CountUp end={totalTeamCount} />
                    </p>
                  </div>
                </div>
                <div className="row justify-content-center mt-2">
                  {depth.map((val, index) => (
                    <div
                      key={index}
                      className="col-3 col-md-2 col-lg-1 mb-4 wow fadeInUp"
                      data-wow-delay="0.2s">
                      <div
                        className="icon-bx-wraper style-1 box-hover text-center"
                        style={{ padding: "7px" }}>
                        <h5
                          className="title"
                          style={{ fontSize: 18, margin: 0 }}>
                          <CountUp end={val} />
                        </h5>
                        <span style={{ fontSize: 12 }}>{index + 1}. Level</span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}

          <div className="container">
            <div className="form-wrapper-box style-1 text-center">
              <div className="section-head ">
                <h4 className="title m-t0">Calculate & Invest</h4>
                <p>Calculate how much you can earn</p>
              </div>
              <div className="form-wrapper">
                <div className="flex-1">
                  <div className="row g-3">
                    <div className="col-xl-3 col-md-6  ">
                      <label className="w-100 text-start px-2">
                        Usd Amount
                      </label>
                      <input
                        name="usdAmount"
                        type="number"
                        required=""
                        value={calcUsdAmount}
                        placeholder="Please Enter Usd Amount"
                        onChange={handleCalculate}
                        className="form-control"
                      />
                      {calcError && (
                        <small className="text-danger error">
                          Minimum Deposit Amount $10.
                        </small>
                      )}
                    </div>
                    <div className="col-xl-3 col-md-6 ">
                      <label className="w-100 text-start px-2">
                        <span>Pol Amount</span>
                      </label>
                      <input
                        name="mtcAmount"
                        type="number"
                        value={calcMtcAmount}
                        required=""
                        onChange={handleCalculate}
                        placeholder="Pol amount"
                        className="form-control"
                      />
                      <div className="container">
                        <div className="row mt-3">
                          <button
                            className="col btn btn-outline-primary p-1 mr-1"
                            style={{
                              maxWidth: "20%",
                              marginRight: "5%",
                              marginLeft: "5%",
                              fontSize: 16,
                            }}
                            onClick={() => {
                              handleRate(25);
                            }}>
                            %25
                          </button>
                          <button
                            className="col btn btn-outline-primary p-1 mr-1"
                            style={{
                              maxWidth: "20%",
                              marginRight: "5%",
                              fontSize: 16,
                            }}
                            onClick={() => {
                              handleRate(50);
                            }}>
                            %50
                          </button>
                          <button
                            className="col btn btn-outline-primary p-1 mr-1"
                            style={{
                              maxWidth: "20%",
                              marginRight: "5%",
                              fontSize: 16,
                            }}
                            onClick={() => {
                              handleRate(75);
                            }}>
                            %75
                          </button>
                          <button
                            className="col btn btn-outline-primary p-1"
                            style={{
                              maxWidth: "20%",
                              marginRight: "5%",
                              fontSize: 16,
                            }}
                            onClick={() => {
                              handleRate(100);
                            }}>
                            %100
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-md-6 ">
                      <label className="w-100 text-start px-2">
                        Max. USD Profit
                      </label>
                      <input
                        name="dzName"
                        type="text"
                        required=""
                        value={hndPrsFl(calcUsdAmount * 2)}
                        disabled
                        placeholder="Maximum $ earning"
                        className="form-control"
                      />
                    </div>
                    <div className="col-xl-3 col-md-6 ">
                      <label className="w-100 text-start px-2">
                        Max. Pol Profit
                      </label>
                      <input
                        name="dzName"
                        type="text"
                        required=""
                        value={hndPrsFl(calcMtcAmount * 2)}
                        disabled
                        placeholder="Maximum Pol earning"
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                {!isLogin && <ConnectButton />}
                {isLogin && (
                  <button
                    type="submit"
                    className="btn btn-lg btn-gradient btn-primary btn-shadow"
                    onClick={() => handleOpen()}>
                    Deposit
                  </button>
                )}
              </div>
            </div>
          </div>
          <img className="bg-shape1" src={Shape1} alt="" />
        </section>
        <section
          className="clearfix section-wrapper1"
          style={{
            backgroundColor: "var(--body-bg)",
            marginTop: 70,
            marginBottom: 70,
          }}>
          <div className="container">
            <div className="section-head text-center">
              <h2 className="title">
                Frequently
                <span className="text-primary"> Asked </span> Questions
              </h2>
            </div>
            <div className="row sp60">
              <Accordion defaultActiveKey={["0"]}>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>What is Matic Fund?</Accordion.Header>
                  <Accordion.Body
                    style={{
                      background: "var(--body-bg)",
                      color: "var(--body-color)",
                    }}>
                    Matic Fund is a smart contract operating on the Polygon
                    Blockchain network. This platform allows investors to
                    contribute solely using Pol Tokens and earn daily returns
                    in Pol Token.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    What is the Daily Earning Rate of Matic Fund?
                  </Accordion.Header>
                  <Accordion.Body
                    style={{
                      background: "var(--body-bg)",
                      color: "var(--body-color)",
                    }}>
                    Base Rate: Daily earnings of 0.5% of your Pol investment.
                    Hold Bonus: An additional 0.05% earning for every 24 hours
                    without withdrawal, up to a maximum of 0.5%. Maximum Daily
                    Rate: Up to 1% with the Base Rate and Hold Bonus combined.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    What are the Investment Limits?
                  </Accordion.Header>
                  <Accordion.Body
                    style={{
                      background: "var(--body-bg)",
                      color: "var(--body-color)",
                    }}>
                    Minimum Investment: Pol Tokens worth $10. Maximum
                    Investment: Pol Tokens worth $1,000,000. Maximum Count of
                    Investments: 300.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    When are My Transactions Approved?
                  </Accordion.Header>
                  <Accordion.Body
                    style={{
                      background: "var(--body-bg)",
                      color: "var(--body-color)",
                    }}>
                    Deposit Approval: Automatically approved upon receiving 2
                    confirmations on the Polygon Blockchain at the time of
                    investment. Withdrawals: Approved by the smart contract and
                    sent immediately. Referral Earnings: Distributed
                    automatically and immediately when your referral makes an
                    investment.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>Is Matic Fund Secure?</Accordion.Header>
                  <Accordion.Body
                    style={{
                      background: "var(--body-bg)",
                      color: "var(--body-color)",
                    }}>
                    Matic Fund is managed by a fully automated smart contract on
                    the Polygon network. Your investments are secured without
                    the possibility of interference from third parties or the
                    developers.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                  <Accordion.Header>
                    How Does the Earning Mechanism of Matic Fund Work?
                  </Accordion.Header>
                  <Accordion.Body
                    style={{
                      background: "var(--body-bg)",
                      color: "var(--body-color)",
                    }}>
                    Earnings are generated based on the volatility of the Pol
                    Token price. In the event of a price increase, the maximum
                    withdrawable amount (in USD) can be up to twice the
                    investment. If the price decreases, the maximum amount of
                    Pol that can be withdrawn can also reach up to twice the
                    investment. This offers the opportunity to benefit from both
                    price rises and falls, but earnings are capped, and
                    investment risks should be carefully assessed.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                  <Accordion.Header>
                    What is the Source of Earnings for Matic Fund?
                  </Accordion.Header>
                  <Accordion.Body
                    style={{
                      background: "var(--body-bg)",
                      color: "var(--body-color)",
                    }}>
                    Matic Fund profits from changes in the Pol price. The
                    contract profits as the price rises; when the price falls,
                    new investors add more Pol, balancing the system. This
                    creates a sustainable ecosystem as the community grows.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </section>

        <section className="content-inner bg-light icon-section section-wrapper2">
          <div className="container">
            <div className="section-head text-center">
              <h2 className="title">
                The smart contract
                <span className="text-primary"> rules </span> are as follows.
              </h2>
            </div>
            <div className="row sp60">
              <OneStop />
            </div>
          </div>
          <img className="bg-shape1" src={Shape1} alt="" />
        </section>
        <section className="content-inner bg-white blog-wrapper">
          <img className="bg-shape1" src={Shape1} alt="" />

          <div className="container">
            <div className="row">
              <div className="col-xl-7 col-lg-12">
                <div className="section-head ">
                  <h6 className="sub-title text-primary">FROM OUR BLOG</h6>
                  <h2 className="title">Recent News &amp; Updates</h2>
                </div>
                <RecentNews news={news} />
              </div>
              <div className="col-xl-5 col-lg-12 m-b30 ">
                <div
                  className="dz-card style-2"
                  style={{ backgroundImage: "url(" + bloglg + ")" }}>
                  <div className="dz-category">
                    <ul className="dz-badge-list">
                      <li>
                        <Link to={"#"} className="dz-badge">
                          01 FEB 2024
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="dz-info">
                    <h2 className="dz-title">
                      <Link to={"#"} className="text-white">
                        MaticFund directly published on Polygon Network.
                      </Link>
                    </h2>
                    <div className="dz-meta">
                      <ul>
                        <li className="post-author">
                          <Link to={"#"}>
                            <img src={avatar3} alt="" className="me-2" />
                            <span>By Matic Fund</span>
                          </Link>
                        </li>
                        <li className="post-date">
                          <Link to={"#"}> 01 Feb 2024</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Modal
        show={open}
        onHide={() => {
          if (!loading) setOpen(false);
        }}>
        <Modal.Header closeButton>
          <Modal.Title>Summary</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {totalDeposits < 1 && (
            <div>
            Your Sponsor Wallet Address is
            <div className="d-flex justify-content-center align-items-center">
              <input
                style={{ maxHeight: 40, marginTop:10, marginBottom: 10, marginRight:10, textAlign: "center", minWidth: "75%" }}
                type="text"
                className="form-control"
                disabled
                value={cookies.get("refAddress") ? cookies.get("refAddress").substring(0,10) + "......" +  cookies.get("refAddress").substring(32,42) : "Please click again your sponsor link."}
              />
              <button className="btn btn-danger" style={{maxHeight: 40, marginTop:10, marginBottom: 10, textAlign: "center"}} onClick={()=>{
                cookies.set('refAddress',"");
              }}> Delete</button>
            </div>
            </div>
            
          )}
          

          You are about to invest <b>${calcUsdAmount}</b> for{" "}
          <b>{calcMtcAmount} Pol</b> tokens.
          <br />
          <br />
          Your package will end when you receive either{" "}
          <b>${calcUsdAmount * 2}</b> or <b>{calcMtcAmount * 2} Pol</b>{" "}
          payments. <br /> <br />
          Do you confirm your investment?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            disabled={loading}
            onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button variant="primary" disabled={loading} onClick={handleSubmit}>
            {loading ? "Loading" : "Continue"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default Home;
